import React from 'react';

import Layout from '../components/Layout';
import Header from '../components/Header';
import Footer from '../components/Footer';

import demo1 from '../assets/images/demoscreen1test2.png';
import demo2 from '../assets/images/demoscreen2test.png';

import gplay from '../assets/images/google-play-badge.svg';
import astore from '../assets/images/app-store-badge.svg';

import { iosLink, androidLink } from '../assets/constants';

const IndexPage = () => (
  <Layout>
    <Header home={true}/>

    <header className="masthead" id="page-top">
      <div className="container h-100">
        <div className="row h-100">
          <div className="col-lg-7 my-auto">
            <div className="header-content mx-auto">
              <h1 className="mb-5">
                Easily connect with the people around you on Nearo
              </h1>
            <div className="badges">
              <a className="badge-link" href={androidLink}>
                <img src={gplay} alt="" />
              </a>
              <a className="badge-link" href={iosLink}>
                <img src={astore} alt="" />
              </a>
            </div>
            </div>
          </div>
          <div className="col-lg-5 my-auto">
            <div className="device-container">
              <div className="device-mockup iphone6 portrait white">
                <div className="device">
                  <div className="screen">
                    <img src={demo1} className="img-fluid" alt="" />
                  </div>
                  <div className="button"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>


    <section className="features"  id="features">
      <div className="container">
        {/* <div className="section-heading text-center">
          <h2>Your Local Social Network</h2>
          <h2>What's Nearo?</h2>

          <p className="text-muted">
            See what your community is up to!
          </p>
          <hr />
        </div> */}
        <div className="row">
          <div className="col-lg-4 my-auto">
            <div className="device-container">
              <div className="device-mockup iphone6 portrait black">
                <div className="device">
                  <div className="screen">
                    <img src={demo2} className="img-fluid" alt="" />
                  </div>
                  <div className="button"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8 my-auto">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6">
                  <div className="feature-item">
                    <i className="icon-screen-smartphone text-primary"></i>
                    <h3>What's happening nearby?</h3>
                    <p className="text-muted">
                      Quickly see what people are talking about in your area and join in on the conversation.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="feature-item">
                    <i className="icon-camera text-primary"></i>
                    {/* <h3>Meet new people</h3>
                    <p className="text-muted">
                      Want to expand your social circle?
                      With nearo, you can chat and meet with other users nearby.
                    </p> */}
                    <h3>Meet new people</h3>
                    <p className="text-muted">
                      Want to expand your social circle?
                      With nearo, you can chat and meet with other users nearby.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="feature-item">
                    <i className="icon-present text-primary"></i>
                    <h3>Become part of the community</h3>
                    <p className="text-muted">
                    Share your thoughts, questions, news or events with users in your area.
                    New on campus? Have questions? Ask the community!
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="feature-item">
                    <i className="icon-lock-open text-primary"></i>
                    <h3>New place? New posts!</h3>
                    <p className="text-muted">
                    When you move around you will see posts relevant to the area you are in.
                    See what locals, friends and neighbors are talking about, or find what’s happening on campus.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Footer />
  </Layout>
);

export default IndexPage;
